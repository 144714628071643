import React from 'react'
import { graphql } from 'gatsby'
import {
  Flex,
  Box,
  Text,
  Badge,
  Embed,
  Button,
  Link,
  Container,
  Grid
} from 'theme-ui'
import get from 'lodash/get'

import Seo from '../components/shared/seo'
import Layout from '../components/shared/layout'
// import Tags from '../components/shared/tags'

import Hero from '../components/shared/hero'
import ArticleNav from '../components/shared/article-nav'

class ArtistPageTemplate extends React.Component {
  render() {
    const page = get(this.props, 'data.contentfulTabPage')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')

    return (
      <Layout location={this.props.location}>
        <Seo
          title={page.title}
          //   description={page.description.childMarkdownRemark.excerpt}
          //   image={`http:${page.heroImage.resize.src}`}
        />
          <Container as="article" sx={{display: 'flex', justifyContent: "center", flexDirection: 'column', m: 'auto'}}>
            <Hero
              title={page.title}
            />
            <Grid sx={{m: 'auto', maxWidth: '50rem', p: 4, gridGap: 4}}>
            <Flex as="section" sx={{ justifyContent: 'space-between'}}>
              <Text as="p"><Text sx={{color: "primary", fontWeight: "bold"}}>Artist:</Text> {page.artist?.title}</Text>
              <Text as="p">
                <Text sx={{color: "primary", fontWeight: "bold"}}>Tuning:</Text>{' '}
                <Badge py="2px" px="5px" key="tuning">
                  {page.tuning}
                </Badge>
              </Text>
            </Flex>
            <Flex as="section" sx={{ mx: ['0', '10rem'], justifyContent: 'center', gap: 4, flexDirection: 'column' }}>
              {page.tutorialVideo && (
                <Embed
                src={page.tutorialVideo}
                title={`${page.title} video lesson`}
                />
                )}
              {page.tutorialVideo2 && (
                <Embed
                src={page.tutorialVideo2}
                title={`${page.title} video lesson`}
                />
                )}
            </Flex>
            <Box as="section">
              <Link py={1} mb={2} href={page.pdfTab.file.url} target="_blank">
                <Button>PDF Tab</Button>
              </Link>
              <Box
                dangerouslySetInnerHTML={{
                  __html: page.description?.childMarkdownRemark?.html,
                }}
                />
            </Box>
            <ArticleNav next={next} previous={previous} route="tabs"/>
            </Grid>
          </Container>
      </Layout>
    )
  }
}

export default ArtistPageTemplate

export const pageQuery = graphql`
  query TabPageBySlug(
    $slug: String!
    $previousPageSlug: String
    $nextPageSlug: String
  ) {
    contentfulTabPage(slug: { eq: $slug }) {
      artist {
        title
      }
      difficulty
      slug
      tuning
      title
      tutorialVideo
      tutorialVideo2
      description {
        childMarkdownRemark {
          html
        }
      }
      pdfTab {
        file {
          url
        }
      }
    }
    previous: contentfulTabPage(slug: { eq: $previousPageSlug }) {
      slug
      title
    }
    next: contentfulTabPage(slug: { eq: $nextPageSlug }) {
      slug
      title
    }
  }
`
